import { Video } from '/components/buildingBlocks/collab/Video'
import { Visual } from '/components/buildingBlocks/Visual'
import { useInView, animated, config } from '@react-spring/web'
import { useClientConfig } from '/machinery/ClientConfig'

import poster from '/images/video-poster.webp'
import circle8Outline from '/images/circle8-outline-yellow.raw.svg'
import styles from './VideoWithBackground.css'

export function VideoWithBackground({ layoutClassName }) {
  const config = useClientConfig()

  return (
    <div className={cx(styles.component, layoutClassName)}>
      <AnimatedHeadingLeft layoutClassName={styles.headingLeftLayout} />
      <AnimatedHeadingRight layoutClassName={styles.headingRightLayout} />
      <div className={styles.container}>
        <Video
          sources={config.promoVideoSources}
          layoutClassName={styles.videoLayout}
          {...{ poster }}
        />
      </div>
      <div className={styles.visual}>
        <Visual src={circle8Outline} />
      </div>
    </div>
  )
}

function AnimatedHeadingLeft({ layoutClassName }) {
  return (
    <AnimatedHeadingBase from={{ x: -200 }} className={layoutClassName}>
      Aston Martin
    </AnimatedHeadingBase>
  )
}

function AnimatedHeadingRight({ layoutClassName }) {
  return (
    <AnimatedHeadingBase from={{ x: 200 }} className={layoutClassName}>
      Aramco
    </AnimatedHeadingBase>
  )
}

function AnimatedHeadingBase({ children, from = { x: 0 }, className }) {
  const [inViewRef, springs] = useInView(() => ({ from, to: { x: 0 }, config: config.slow }))

  return (
    <animated.h2
      ref={inViewRef}
      className={cx(styles.componentAnimatedHeadingBase, className)}
      style={springs}
    >
      {children}
    </animated.h2>
  )
}
