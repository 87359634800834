import styles from './Visual.css'

export function Visual({ src, layoutClassName = undefined }) {
  return (
    <span
      role='presentation'
      className={cx(styles.component, layoutClassName)}
      dangerouslySetInnerHTML={{ __html: src }}
    />
  )
}
